import { ParsedSearchParams, toSearchParams } from './searchparams'

export const getSearchHref = (
  authorityId: string,
  searchParams: ParsedSearchParams,
) => {
  if (authorityId) {
    return `/authority-files?authorityfileid=${authorityId}`
  }

  if (searchParams) {
    return `/search?${toSearchParams(searchParams)}`
  }

  return '/search'
}
