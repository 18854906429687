import React, { useEffect, useState } from 'react'
import { useLoaderData } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import { ErrorPage } from 'src/components/shared/ErrorPage'
import { Grid } from 'src/components/shared/Grid'
import { SearchLoaderDataProps } from 'src/contexts/loader'

import { useSearchTranslation } from '../../utils'
import { Filters } from './Filters'
import { ActiveFilters } from './Filters/ActiveFilters'
import { Hits } from './Hits'
import { Tools } from './Tools'
import { Paging } from './Tools/Paging'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  marginTop: {
    marginTop: theme.spacing(10),
  },
  activeFilters: {
    marginTop: theme.spacing(3),
  },
  tools: {
    marginTop: theme.spacing(4),
  },
  hits: {
    marginTop: theme.spacing(3),
  },
  bottomPaging: {
    margin: 'auto',
    width: '50%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
}))

export function ListView() {
  const cls = useStyles()
  const { searchT } = useSearchTranslation()

  const [showError, setShowError] = useState(false)
  const { searchResult } = useLoaderData() as SearchLoaderDataProps

  useEffect(() => {
    if (typeof searchResult === 'undefined') {
      const timeoutId = setTimeout(() => {
        setShowError(true)
      }, 10 * 1000)
      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [searchResult])

  if (showError) {
    return (
      <ErrorPage
        id={'discovery-list-view'}
        errorMessage={searchT('searchFailed')}
      />
    )
  }

  return (
    <div className={cls.root}>
      <Filters />
      <Grid id="searchGrid">
        <div className={cls.marginTop}>
          <ActiveFilters className={cls.activeFilters} />
          <Tools className={cls.tools} result={searchResult} />
        </div>
        <Hits className={cls.hits} result={searchResult} />
        <Paging
          ariaLabel={'Pagination navigation bottom'}
          className={cls.bottomPaging}
          result={searchResult}
        />
      </Grid>
    </div>
  )
}
