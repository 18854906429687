import clsx from 'clsx'
import React, { forwardRef, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'

import Drawer from '@material-ui/core/Drawer'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'

import { useTranslation } from 'src/contexts/i18n'
import { selectors, useSelector } from 'src/contexts/state'

import { SideBar } from '../shared/SideBar'
import { SideBarButton } from '../shared/SideBarButton'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    zIndex: 1200,
  },
  upper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logo: {
    width: '200px',
  },
  sidebar: {
    width: '100%',
  },
}))

interface Props {
  className?: string
}

export const TopBar = forwardRef(function TopBar(props: Props, ref) {
  const cls = useStyles()
  const { t } = useTranslation()
  const sideBarOpen = useSelector(selectors.getSidebarOpen)

  return (
    <Paper
      component="header"
      className={clsx(cls.root, props.className)}
      elevation={24}
      ref={ref}
      square
    >
      <div className={cls.upper}>
        <Link to="/" aria-label={t('topBar.logoLink')}>
          <img
            src="/img/handschriftenportal_logo.svg"
            className={cls.logo}
            alt={t('logo')}
          />
        </Link>
        <SideBarButton />
        <Drawer
          open={sideBarOpen}
          classes={{
            paper: cls.sidebar,
          }}
        >
          <SideBar mobile={true} />
        </Drawer>
      </div>
    </Paper>
  )
})
