import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'

import { SearchBarOrOverviewNavigation } from 'src/components/search/SearchBarOrOverviewNavigation'
import { useTranslation } from 'src/contexts/i18n'

const useStyles = makeStyles((theme) => ({
  headerNav: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  root: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'row',
    height: theme.mixins.toolbar.minHeight,
    width: '100%',
    zIndex: theme.zIndex.drawer + 1,
    paddingLeft: theme.spacing(8),
  },
  left: {
    paddingLeft: theme.spacing(3),
  },
  logo: {
    width: '200px',
  },
  margin: {
    [theme.breakpoints.down('lg')]: {
      position: 'unset !important',
      marginRight: theme.spacing(4),
    },
  },
  right: {
    width: '50%',
    position: 'fixed',
    left: 'calc(100% / 3)',
    [theme.breakpoints.down('sm')]: {
      left: '40%',
    },
    // classes coming from hsp-fo-search
    '& .searchSelect': {
      [theme.breakpoints.up('sm')]: {
        borderLeft: 'thin outset' + theme.palette.platinum.main,
      },
    },
  },
}))

export function TopBar() {
  const cls = useStyles()
  const { t } = useTranslation()
  const location = useLocation()
  const [isFromWorkspace, setIsFromWorkspace] = useState(false)

  useEffect(() => {
    if (location.search.includes('fromWorkspace=true')) {
      setIsFromWorkspace(true)
    } else setIsFromWorkspace(false)
  }, [location])

  return (
    <Paper component="header" className={cls.root} elevation={24} square>
      <div className={cls.headerNav}>
        <div className={cls.left}>
          <Link aria-label={t('topBar.logoLink')} to="/">
            <img
              className={cls.logo}
              src="/img/handschriftenportal_logo.svg"
              alt={t('topBar.logo')}
            />
          </Link>
        </div>
        <div
          className={isFromWorkspace ? clsx(cls.right, cls.margin) : cls.right}
        >
          <SearchBarOrOverviewNavigation />
        </div>
      </div>
    </Paper>
  )
}
