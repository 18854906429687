import clsx from 'clsx'
import { WorkspaceResource } from 'hsp-fo-workspace/types'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import MuiButtonBase from '@material-ui/core/ButtonBase'
import MuiLink from '@material-ui/core/Link'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'

import { FabButton } from 'src/components/search/shared/FabButton'
import { WorkspaceToast } from 'src/components/search/shared/WorkspaceToast'
import { useSearchTranslation } from 'src/components/search/utils'
import { actions } from 'src/contexts/actions/actions'
import { searchActions } from 'src/contexts/actions/searchActions'
import { HspDigitized } from 'src/contexts/discovery'
import { createWorkspacePermalink, useModules } from 'src/contexts/modules'
import { searchSelectors } from 'src/contexts/state'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 120,
    position: 'relative',
  },
  navigation: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  buttonWrapper: {
    borderRadius: 45,
  },
  button: {
    margin: 3,
    padding: 6,
    borderRadius: 45,
    cursor: 'pointer',
    background: theme.palette.liver.main,
  },
  link: {
    padding: 0,
    margin: 0,
  },
  on: {
    background: theme.palette.secondary.main,
  },
  tooltip: {
    maxWidth: 450,
  },
}))

interface Props {
  className?: string
  hspDigitizeds: HspDigitized[]
}

export function Thumbnails(props: Props) {
  const { className, hspDigitizeds } = props

  const cls = useStyles()
  const { searchT } = useSearchTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { workspace } = useModules()

  const searchParams = useSelector(searchSelectors.getSearchParams)
  const selectedResources = useSelector(searchSelectors.getSelectedResources)

  const [current, setCurrent] = useState(0)

  const [toastMessage, setToastMessage] = useState(null as null | string)

  const handleToastClose = () => {
    setToastMessage(null)
  }

  const digitizedsWithThumbnails = hspDigitizeds.filter(
    (digi) => digi['thumbnail-uri-display'],
  )

  if (digitizedsWithThumbnails.length === 0) {
    return null
  }

  function addResource(res: WorkspaceResource) {
    // then create a permalink for that resource
    const permalink = createWorkspacePermalink(res.type, res.id)
    // and add the resource info to the workspace,
    const resources = workspace.getResources()
    const notInWorkspace = resources.every(
      ({ manifestId }) => res.id !== manifestId,
    )
    if (notInWorkspace) {
      if (res.type.includes('description')) {
        workspace.addResource({
          ...res,
          permalink,
          id: res.id,
        })
      } else {
        workspace.addResource({
          ...res,
          permalink,
          manifestId: res.id,
          id: 'hsp-window-' + uuidv4(),
        })
      }

      // // and add the resource info to the search module itself
      // // so it will be marked as selected
      dispatch(
        searchActions.setSelectedResources([
          ...selectedResources,
          { ...res, query: undefined },
        ]),
      )
      // and update the number in the workspace button in the sidebar.
      dispatch(actions.setWorkspaceBadgeCount(workspace.getResources().length))
    }
  }

  function openResource(res: WorkspaceResource) {
    addResource(res)
    dispatch(searchActions.setSearchParams(searchParams))
    navigate('/workspace')
  }

  function selectResource(what: 'select' | 'unselect', res: WorkspaceResource) {
    if (what === 'select') {
      addResource(res)
      setToastMessage('addToWorkspace')
    }
    if (what === 'unselect') {
      if (res.type.includes('description')) {
        workspace.removeResource(res)
      } else {
        // remove including all iiif duplicates
        workspace.removeResources(res.id) // in search id is a manifestId
      }
      // and remove the resource info from the search module itself
      // so it becomes unmarked.
      dispatch(
        searchActions.setSelectedResources(
          selectedResources.filter((r) => r.id !== res.id),
        ),
      )
      // and update the number in the workspace button in the sidebar.
      dispatch(actions.setWorkspaceBadgeCount(workspace.getResources().length))
      setToastMessage('removeFromWorkspace')
    }
  }

  function renderImage(digi: HspDigitized) {
    return (
      <img
        alt={searchT('resource', 'thumbnailImage')}
        src={digi['thumbnail-uri-display'] as string}
        width={120}
      />
    )
  }

  const currentDigi = digitizedsWithThumbnails[current]
  const isManifest = currentDigi['manifest-uri-display']
  const isExternal = !isManifest && currentDigi['external-uri-display']
  const isIncomplete = !(isManifest ?? isExternal)

  const type = 'iiif:manifest'
  const res = {
    id: currentDigi['manifest-uri-display'],
    type,
  }

  const isAlreadyAdded = selectedResources.some(
    (r) => r.id === currentDigi['manifest-uri-display'],
  )

  return (
    <div className={clsx(cls.root, className)}>
      {isManifest && (
        <>
          <Tooltip
            title={searchT('resources', 'openManifestInWorkarea') as string}
            className={cls.tooltip}
          >
            <MuiLink
              aria-label={searchT('resources', 'openManifestInWorkarea')}
              className={clsx(cls.link)}
              component="button"
              variant="body2"
              onClick={() => openResource(res as WorkspaceResource)}
              tabIndex={0}
            >
              {renderImage(currentDigi)}
            </MuiLink>
          </Tooltip>
          <FabButton
            isAlreadyAdded={isAlreadyAdded}
            isThumbnail={true}
            onClickSelect={() =>
              selectResource('select', res as WorkspaceResource)
            }
            onClickUnselect={() =>
              selectResource('unselect', res as WorkspaceResource)
            }
          />
        </>
      )}
      {isExternal && (
        <Tooltip
          title={searchT('resources', 'openDigitalImagesExternal') as string}
          className={cls.tooltip}
        >
          <MuiLink
            aria-label={searchT('resources', 'openDigitalImagesExternal')}
            className={clsx(cls.link)}
            variant="body2"
            href={currentDigi['external-uri-display'] as string}
            target="_blank"
            tabIndex={0}
          >
            {renderImage(currentDigi)}
          </MuiLink>
        </Tooltip>
      )}
      {isIncomplete && renderImage(currentDigi)}
      <div className={cls.navigation} role="tablist">
        {digitizedsWithThumbnails.length > 1 &&
          digitizedsWithThumbnails.map((digi, index) => (
            <MuiButtonBase
              key={digi.id}
              className={cls.buttonWrapper}
              onClick={() => setCurrent(index)}
              role="tab"
            >
              <span
                className={clsx(cls.button, { [cls.on]: current === index })}
              />
            </MuiButtonBase>
          ))}
      </div>
      <WorkspaceToast
        open={toastMessage !== null}
        handleClose={handleToastClose}
        message={searchT('snackMsg', toastMessage)}
      />
    </div>
  )
}
