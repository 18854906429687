import React from 'react'

import Typography from '@material-ui/core/Typography'

import { useSearchTranslation } from 'src/components/search/utils'
import { HspObjectsByQueryOutput } from 'src/contexts/discovery'

interface Props {
  className?: string
  result: HspObjectsByQueryOutput
}

export function ResultCounts(props: Props) {
  const { className, result } = props
  const { searchTT } = useSearchTranslation()

  return (
    <div className={className}>
      <Typography variant="h1">
        {searchTT(
          {
            numFound: result.metadata.numFound.toString(),
          },
          'numberOfHits',
        )}
      </Typography>
    </div>
  )
}
