import React from 'react'

import {
  ParsedSearchParams,
  useParsedSearchParams,
} from 'src/utils/searchparams'

import { ListView } from './ListView'
import { Overview } from './Overview'

export function Search() {
  const { hspobjectid }: ParsedSearchParams = useParsedSearchParams()

  let children = null

  if (hspobjectid) {
    children = <Overview />
  } else {
    children = <ListView />
  }

  return <div id="hsp-search-main">{children}</div>
}
