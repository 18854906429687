import urlJoin from 'proper-url-join'
import { QueryClient } from 'react-query'
import { LoaderFunctionArgs } from 'react-router-dom'
import WPAPI from 'wpapi'

import { getSlug } from 'src/utils'

import { i18n } from '../i18n'
import { ImageSet } from '../types'
import {
  AcfProject,
  WordpressMenuItem,
  WordpressPage,
  fetchWordpressMenus,
  fetchWordpressPageWithSlugs,
  getProjectPages,
  wordpressEndpoint,
} from '../wordpress'

function getImageSetsFromAcf(acf: Record<string, string>): ImageSet[] {
  const imageSetsFromAcf = []
  let i = 1
  let imageKey = `image_${i}`
  while (imageKey in acf && typeof acf[imageKey] === 'string') {
    imageSetsFromAcf.push({
      url: acf[imageKey],
      ident: acf[`${imageKey}_ident`],
      info: acf[`${imageKey}_info`],
    })
    i++
    imageKey = `image_${i}`
  }
  return imageSetsFromAcf
}

function replaceImageUrls(sets: ImageSet[]) {
  return sets.map((set) => ({
    ...set,
    url: urlJoin(
      wordpressEndpoint,
      'wp-content',
      set.url.replace(/^.*\/wp-content\//, ''),
    ),
  }))
}

export type HomeLoaderDataProps = {
  imageSets?: ImageSet[]
  error?: Record<string, unknown>
}

export async function homeLoader() {
  const wp = new WPAPI({
    endpoint: urlJoin(wordpressEndpoint, '/wp-json'),
  })

  try {
    const pages = await wp.pages().slug('startseite')
    const acf = pages[0].acf
    const sets = getImageSetsFromAcf(acf)
    const replacedSets = replaceImageUrls(sets)

    return { imageSets: replacedSets, error: undefined }
  } catch (error: unknown) {
    return { imageSets: undefined, error }
  }
}
export interface CMSLoaderDataProps {
  page: WordpressPage
  error: string
}

export async function cmsLoader({ params }: LoaderFunctionArgs) {
  const { slug } = params
  const lang = i18n.language
  if (!slug) {
    throw new Response('Slug is missing', { status: 400 })
  }

  try {
    const page = await fetchWordpressPageWithSlugs(
      wordpressEndpoint,
      slug,
      lang,
    )
    return { page }
  } catch (error) {
    return { page: undefined, error }
  }
}

export interface ProjectsLoaderDataProps {
  projects: AcfProject[]
  error: string
}

export async function projectLoader() {
  try {
    const projects = await getProjectPages()
    return { projects }
  } catch (error) {
    return { projects: undefined, error }
  }
}

function wordpressMenuToItem(wpMenu: WordpressMenuItem): any {
  const slug = getSlug(new URL(wpMenu.url).pathname)
  const url = new URL(urlJoin('/info', '/' + slug), window.location.origin)
  url.search = ''
  url.hash = ''
  return {
    id: wpMenu.ID.toString(),
    label: wpMenu.title,
    children: wpMenu.child_items?.map(wordpressMenuToItem) || [],
    link: url.href,
  }
}

async function fetchMenusWithLanguage(language: string) {
  try {
    const menus = await fetchWordpressMenus(wordpressEndpoint, language)
    return menus.items.map(wordpressMenuToItem)
  } catch (error) {
    return null
  }
}

const menuQuery = (lang: string) => ({
  queryKey: ['menu', lang],
  queryFn: async () => fetchMenusWithLanguage(lang),
})

export const menuLoader = (queryClient: QueryClient) => async () => {
  const languages = ['de', 'en']

  const menuPromises = languages.map(async (language) => {
    const query = menuQuery(language)
    return (
      queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query))
    )
  })

  const [menusDe, menusEn] = await Promise.all(menuPromises)

  return {
    de: menusDe,
    en: menusEn,
  }
}
