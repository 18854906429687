import urlJoin from 'proper-url-join'
import { QueryClient } from 'react-query'

import { discoveryEndpoint, fetchJson } from '../discovery'
import { fetchHspObjectsQuery } from './searchLoader'

export interface AuthItemProps {
  preferredName: string
  variantName: string[] | null
  typeName: string
  identifier: null | string[]
  id: string
  gndId: string
}

export interface AuthorityFileDataProps {
  authItem: AuthItemProps
  numFound: number
  error: Error
}

/**
 * Fetches a authItem and the numFound for it by its id.
 */
export const normLoader =
  (queryClient: QueryClient) =>
  async ({ request }: { request: Request }) => {
    const authorityfileid = new URL(request.url).searchParams.get(
      'authorityfileid',
    )

    const normEndpoint = urlJoin(
      discoveryEndpoint,
      '/authority-files/',
      authorityfileid ?? '',
    )
    const queryKeyNorm = ['authorityFileById', authorityfileid]
    const queryFnNorm = () => fetchJson(fetch, normEndpoint)

    const authItem = await queryClient.fetchQuery(queryKeyNorm, queryFnNorm)

    const params = {
      q: `${authorityfileid}`,
      fq: undefined,
      start: undefined,
      rows: undefined,
    }

    const hspObjects = await fetchHspObjectsQuery(params, queryClient)
    const numFound = hspObjects.metadata.numFound ?? 0

    return { authItem, numFound }
  }
