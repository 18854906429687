import { createReducer } from '@reduxjs/toolkit'

import { searchActions } from '../actions/searchActions'
import { SearchState } from '../types'
import { extFieldGroups } from './extFieldGroups'

export const searchState: SearchState = {
  showFilterList: false,
  hitListVariant: 'expanded',
  selectedResources: [],
  searchTerm: '',
  searchField: 'FIELD-GROUP-ALL',
  searchParams: {},
  extendedFieldsInfo: extFieldGroups,
  hasFetchedExtFields: false,
  authorityId: '',
}

export const searchReducer = createReducer<SearchState>(
  searchState,
  (builder) => {
    builder.addCase(searchActions.setModifiedFilterQuery, (state, action) => {
      state.modifiedFilterQuery = action.payload
    })
    builder.addCase(searchActions.toggleFilterList, (state) => {
      state.showFilterList = !state.showFilterList
    })
    builder.addCase(searchActions.setHitListVariant, (state, action) => {
      state.hitListVariant = action.payload
    })
    builder.addCase(searchActions.setSelectedResources, (state, action) => {
      state.selectedResources = action.payload
    })

    builder.addCase(searchActions.setSearchTerm, (state, action) => {
      state.searchTerm = action.payload
    })
    builder.addCase(searchActions.setSearchField, (state, action) => {
      state.searchField = action.payload
    })
    builder.addCase(searchActions.setSearchParams, (state, action) => {
      state.searchParams = action.payload
    })
    builder.addCase(searchActions.setExtSearchFieldsInfo, (state, action) => {
      state.extendedFieldsInfo = action.payload
    })
    builder.addCase(
      searchActions.setHasFetchedExtFieldInfo,
      (state, action) => {
        state.hasFetchedExtFields = action.payload
      },
    )
    builder.addCase(searchActions.setAuthorityId, (state, action) => {
      state.authorityId = action.payload
    })
  },
)
