import React from 'react'

import { Projects as ProjectsModule } from 'src/components/projects/index'
import { useSetupModulesForPage } from 'src/contexts/modules'
import { useTrackPageView } from 'src/contexts/tracking'

export function Projects() {
  useSetupModulesForPage()
  useTrackPageView('Projects')

  return <ProjectsModule />
}
