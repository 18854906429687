import urlJoin from 'url-join'
import WPAPI from 'wpapi'

export const wordpressEndpoint = '/api/wordpress'

export interface WordpressPage {
  id: number
  title: {
    rendered?: string
  }
  content: {
    rendered: string
  }
}

// what we have is page = [{WPPAge}, {object containing paging and co}]
export interface WordpressMenuItem {
  ID: number
  title: string
  url: string
  // eslint-disable-next-line camelcase
  child_items?: WordpressMenuItem[]
}

export interface WordpressMenu {
  taxonomy: string
  items: WordpressMenuItem[]
}

// fetches Menu for given language
export function fetchWordpressMenus(
  wordpressEndpoint: string,
  lang: string,
): Promise<WordpressMenu> {
  const wp = new WPAPI({
    endpoint: urlJoin(wordpressEndpoint, '/wp-json'),
  })

  wp.menuLocation = wp.registerRoute('menus/v1', '/menus/(?P<lang>)')

  return wp
    .menuLocation()
    .lang('menu-' + lang)
    .get()
}

export function fetchWordpressPageWithSlugs(
  wordpressEndpoint: string,
  slug: string,
  lang: string,
): Promise<WordpressPage[]> {
  const wp = new WPAPI({
    endpoint: urlJoin(wordpressEndpoint, 'wp-json'),
  })

  // get page according to i18n lang and slug
  return wp.pages().slug(slug).param('lang', lang).get()
}

export interface AcfProject {
  id: string
  descriptionShort: string
  start: string
  end: string
  description: string
  publications: string
  links: string
  contact: string
  involved: string
  involvedInstitutions: string
  support: string
  projectName: string
  projectTitle: string
  thumbnail: string
  thumbnailInfo: string
  image1: string
  image1Info: string
  image2?: string
  image2Info?: string
  image3?: string
  image3Info?: string
  runningTime?: string
  status?: boolean
  manuscriptListing?: string
  kodLink?: string
  className?: string
}

interface WPPages {
  pages: AcfProject[]
  _paging: { total: number; totalPages: number }
}

async function getAllPages(id: number, perPage = 100): Promise<WPPages[]> {
  let allPages: WPPages[] = []
  const wp = new WPAPI({
    endpoint: urlJoin(wordpressEndpoint, '/wp-json'),
  })

  function fetchPages(
    id: number,
    page: number,
    perPage = 100,
  ): Promise<WPPages> {
    return wp
      .pages()
      .param('parent', id)
      .param('per_page', perPage)
      .param('page', page)
      .get()
  }
  const firstPage = await fetchPages(id, 1, perPage)

  const totalPages = firstPage._paging.totalPages
  allPages = allPages.concat(firstPage)

  for (let page = 2; page <= totalPages; page++) {
    const fetchedPages = await fetchPages(id, page, perPage)
    allPages = allPages.concat(fetchedPages)
  }

  return allPages
}

export function getProjectPages(): Promise<AcfProject[]> {
  const wp = new WPAPI({
    endpoint: urlJoin(wordpressEndpoint, '/wp-json'),
  })

  return wp
    .pages()
    .slug('projekte')
    .then((parentPage) => getAllPages(parentPage[0].id)) // get pages with id
    .then((pages) => pages.map((page: { acf: AcfProject }) => page.acf)) // extract acf information
}
