import React from 'react'

import { Cms as CmsModule } from 'src/components/cms/index'
import { useSetupModulesForPage } from 'src/contexts/modules'
import { useTrackPageView } from 'src/contexts/tracking'

export function Cms() {
  useSetupModulesForPage()
  useTrackPageView('Cms')

  return <CmsModule />
}
