import { HspThemeProvider } from 'hsp-web-module'
import { SnackbarProvider } from 'notistack'
import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'

import { FeatureFlags, FeatureFlagsContext } from 'src/contexts/features'
import { initI18n } from 'src/contexts/i18n'
import { ModuleContext, createHspModules } from 'src/contexts/modules'
import { Router } from 'src/contexts/router'
import { makeStore } from 'src/contexts/state'
import { Tracker, TrackerContext } from 'src/contexts/tracking'

const reactQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 3 * 60 * 1000,
      retry: 3,
    },
  },
})
export function App() {
  initI18n()
  const store = makeStore()
  const featureFlags = React.useMemo(() => new FeatureFlags(), [])
  const tracker = React.useMemo(() => new Tracker(), [])
  const modules = createHspModules()

  return (
    <QueryClientProvider client={reactQueryClient}>
      <HspThemeProvider>
        <Provider store={store}>
          <FeatureFlagsContext.Provider value={featureFlags}>
            <TrackerContext.Provider value={tracker}>
              <ModuleContext.Provider value={modules}>
                <SnackbarProvider
                  maxSnack={3}
                  autoHideDuration={4000}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                  <Router />
                </SnackbarProvider>
              </ModuleContext.Provider>
            </TrackerContext.Provider>
          </FeatureFlagsContext.Provider>
        </Provider>
      </HspThemeProvider>
    </QueryClientProvider>
  )
}
