import { combineReducers } from '@reduxjs/toolkit'

import { GroupedExtendedSearchState, SearchState, State } from '../types'
import { appReducer } from './appReducer'
import { extSearchReducer } from './extSearchReducer'
import { searchReducer } from './searchReducer'

export interface CombinedState {
  app: State
  search: SearchState
  extSearch: GroupedExtendedSearchState
}

export const rootReducer = combineReducers<any>({
  app: appReducer,
  search: searchReducer,
  extendedSearch: extSearchReducer,
})
